<template>
  <div>
    <b-button @click="$router.go(-1)" class="mb-2" variant="success">
      <fa-icon icon="arrow-left"> </fa-icon>Voltar
    </b-button>
    <b-row>
      <b-col>
        <Card class="mb-2" :title="'Criar novo Modelo'">
          <div
            class="py-3 d-flex justify-content-between flex-column"
            slot="card-body"
          >
            <b-row>
              <b-col md="6">
                <FormSelect
                  :title="'Para qual Aplicação:'"
                  :options="applicationSelectOptions"
                  v-model="selectedApplication"
                  @changed="application_changed"
                />
              </b-col>
              <b-col md="6" v-if="selectedApplication !== ''">
                <FormSelect
                  :title="'Tipo de Notificação:'"
                  :options="notificationSelectOptions"
                  v-model="selectedNotificationType"
                  @changed="notification_type_changed"
                />
              </b-col>
            </b-row>
          </div>
        </Card>
        {{filteredTemplates}}
      </b-col>      
    </b-row>
    <template v-if="filteredTemplates.length > 0 && showEditor == false">
      <b-alert show>
        <p class="d-flex align-items-center">
          Você já possui
          <b-badge pill variant="success mx-2">
            {{ filteredTemplates.length }}
          </b-badge>
          modelo desse tipo (listados abaixo), deseja criar um novo?
          <b-button class="ml-4" @click="showEditor = true" variant="info"
            >Continuar <fa-icon icon="arrow-right"></fa-icon
          ></b-button>
        </p>
        <hr />
        <p>
          Ou se preferir, clique em "Ver" para visualizar ou "Editar" para
          alterar um modelo.
        </p>
      </b-alert>
      <b-row>
        <b-col>
          <Card>
            <template #card-body>
              <TableTemplatesList :templates="filteredTemplates" />
            </template>
          </Card>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="showEditor" class="mb-2">
      <b-col md="4">
        <ModelConfig
          ref="headerSlider"
          :title="'Cabeçalho'"
          :templates="headerTemplates"
          v-model="headerSelected"
        />
      </b-col>
      <b-col md="4">
        <ModelConfig
          ref="footerSlider"
          :title="'Rodapé'"
          :templates="footerTemplates"
          v-model="footerSelected"
        />
      </b-col>
      <b-col>
        <BackgroundSlider v-model="backgroundSelected" />
      </b-col>
    </b-row>
    <b-row v-if="showEditor && headerSelected !== '' && footerSelected !== ''">
      <b-col>
        <Card class="p-4">
          <template #card-body>
            <b-row class="mb-4">
              <b-col class="d-flex align-items-baseline">
                <ListTags
                  class="mr-4"
                  v-if="requiredVariables.length > 0"
                  :variables="requiredVariables"
                  :element="'requiredVariables'"
                  :title="'Tags Obrigatórias'"
                />
                <ListTags
                  v-if="optionalVariables.length > 0"
                  :variables="optionalVariables"
                  :element="'optionalVariables'"
                  :title="'Tags Opcionais'"
                />
              </b-col>
              <b-col class="d-flex justify-content-end">
                <div>
                  <b-button variant="success" @click="show_preview"
                    >Visualizar</b-button
                  >
                  <b-button variant="success" @click="save_changes"
                    >Salvar</b-button
                  >
                </div>
              </b-col>
            </b-row>

            <!-- <div>
              <b-button @click="submit" variant="success">Salvar</b-button>
            </div> -->
            <MailEditor
              :background="backgroundSelected"
              v-if="headerSelected !== '' && footerSelected !== ''"
              ref="mail_editor"
            />
          </template>
        </Card>
      </b-col>
    </b-row>
    <MailEditor
      ref="html_generator"
      :design="designFull"
      :background="backgroundSelected"
      style="display: none"
    />
    <SweetModal
      ref="preview_modal"
      overlay-theme="dark"
      width="80%"
      hide-close-button
    >
      <template #title>
        <strong class="text-muted"
          >Este modelo já consta como Ativo para esse tipo de notificação,
          deseja alterar?</strong
        >
      </template>
      <iframe
        :srcdoc="existingTemplate.html"
        frameborder="0"
        style="
          width: 100%;
          height: 70vh;
          overflow-y: scroll;
          border: 1px solid lightgray;
        "
      ></iframe>
      <template #button>
        <b-button class="mr-2" @click="submit" variant="success"
          >Salvar e Alterar</b-button
        >
        <b-button
          class="mr-2"
          @click="
            model.active = '0';
            submit();
          "
          variant="warning"
          >Salvar sem Alterar</b-button
        >
        <b-button @click="close_preview" variant="gray">Cancelar</b-button>
      </template>
    </SweetModal>
    <SweetModal ref="tagError" icon="warning" hide-close-button>
      <div class="mt-3" v-if="invalidTags.length > 0">
        <p class="lead">As seguintes tags são invalidas</p>
        <span v-for="(tag, idx) in invalidTags" :key="idx">
          {{ getTag(tag) }}
        </span>
        <p class="text-muted mt-3">
          Favor remover do corpo do email as tags mostradas acima
        </p>
      </div>
      <hr v-if="invalidTags.length > 0 && requiredTags.length > 0" />
      <div class="mt-3" v-if="requiredTags.length > 0">
        <p class="lead">As seguintes tags são obrigatórias</p>
        <span v-for="(tag, idx) in requiredTags" :key="idx"
          >{{ getTag(tag) }}
        </span>
        <p class="text-muted mt-3">
          Favor incluir no corpo do email as tags conforme mostradas acima
        </p>
      </div>

      <b-button @click="$refs.tagError.close()" variant="warning">Ok</b-button>
    </SweetModal>
    <SweetModal ref="submit_success" icon="success" blocking hide-close-button>
      <span class="lead">Modelo Salvo com Sucesso!</span>
      <template #button>
        <b-button variant="info" @click="$router.go(-1)">Ok</b-button>
      </template>
    </SweetModal>
    <SweetModal
      ref="show_preview"
      overlay-theme="dark"
      width="80%"
      hide-close-button      
    >
      <iframe
        :srcdoc="previewHtml"
        frameborder="0"
        style="
          width: 100%;
          height: 70vh;
          overflow-y: scroll;
          border: 1px solid lightgray;
        "
      ></iframe>
    </SweetModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      designFull: [],
      previewHtml:'',
      backgroundSelected: "",
      headerSelected: "",
      footerSelected: "",
      showEditor: false,
      selectedApplication: "",
      selectedType: "",
      selectedNotificationType: "",
      existingTemplate: {  },
      invalidTags: [],
      requiredTags: [],
      model:{}
    };
  },
  components: {
    ListTags: () => import("./components/ListTags.vue"),
    MailEditor: () => import("@/components/EmailEditor"),
    FormSelect: () => import("@/components/inputs/Select"),
    TableTemplatesList: () => import("./components/TableTemplatesList.vue"),
    ModelConfig: () => import("./components/ModelConfig.vue"),
    BackgroundSlider: () => import("./components/BackgroundSlider.vue"),
  },

  computed: {
    optionalVariables() {
      const selectedNotificationType = _.filter(
        this.notificationTypes,
        (type) => {
          return type.id == this.selectedNotificationType;
        }
      );

      if (
        selectedNotificationType.length > 0 &&
        selectedNotificationType[0].variables
      ) {
        const normalized = selectedNotificationType[0].variables.split(",");
        return normalized;
      } else {
        return [];
      }
    },
    requiredVariables() {
      const selectedNotificationType = _.filter(
        this.notificationTypes,
        (type) => {
          return type.id == this.selectedNotificationType;
        }
      );
      if (
        selectedNotificationType.length > 0 &&
        selectedNotificationType[0].required_variables
      ) {
        const normalized =
          selectedNotificationType[0].required_variables.split(",");
        return normalized;
      } else {
        return [];
      }
    },

    notificationTypes() {
      return this.$store.state.templates.notificationTypes;
    },
    applicationSelectOptions() {
      return [
        { description: "Selecione", value: "", disabled: true },
        { description: "Todas as Aplicações", value: "all" },
        ...this.$store.state.templates.unityApplications,
      ];
    },
    notificationSelectOptions() {
      let normalized = [];

      _.forEach(this.$store.state.templates.notificationTypes, (t) => {
        const temp = {
          value: t.id,
          description: t.description,
        };
        normalized.push(temp);
      });

      return [{ description: "Selecione", value: "" }, ...normalized];
    },
    filteredTemplates() {
      return _.filter(this.templates, (t) => {
        return (
          t.notification_type_id == this.selectedNotificationType &&
          t.application_id == this.selectedApplication &&
          t.type == "content"
        );
      });
    },
    templates() {
      return this.$store.state.templates.templatesList;
    },
    headerTemplates() {
      return _.filter(this.templates, (template) => {
        return (
          template.type == "header" &&
          (template.notification_type_id == this.selectedNotificationType || template.notification_type_id == '') &&
          template.application_id == this.selectedApplication
        );
      });
    },
    footerTemplates() {
      return _.filter(this.templates, (template) => {
        return (
          template.type == "footer" &&
          (template.notification_type_id == this.selectedNotificationType || template.notification_type_id == '') &&
          (template.application_id == this.selectedApplication ||
            template.application_id == null)
        );
      });
    },
  },
  methods: {
    application_changed(v) {
      this.selectedType = "";
      this.selectedNotificationType = "";
      this.showEditor = false;
    },
    type_changed(v) {
      this.selectedNotificationType = "";
      this.showEditor = false;
    },
    notification_type_changed(v) {
      this.showEditor = false;
      setTimeout(() => {
        if (this.filteredTemplates.length == 0) {
          this.showEditor = true;
        }
      }, 200);
    },
    async show_preview() {
      const header = _.filter(this.templates, (t) => {
        return t.id == this.headerSelected;
      });
      const footer = _.filter(this.templates, (t) => {
        return t.id == this.footerSelected;
      });
      const headerDesign = JSON.parse(header[0].design);

      const footerDesign = JSON.parse(footer[0].design);

      const template = await this.$refs.mail_editor.getDesign();

      this.designFull = [...headerDesign, ...template, ...footerDesign];

      const html = await this.$refs.html_generator.loadDesign();

      this.previewHtml = html;

      this.$refs.show_preview.open();
    },
    async save_changes() {
      const header = _.filter(this.templates, (t) => {
        return t.id == this.headerSelected;
      });
      const footer = _.filter(this.templates, (t) => {
        return t.id == this.footerSelected;
      });

      const headerDesign = JSON.parse(header[0].design);

      const footerDesign = JSON.parse(footer[0].design);

      const template = await this.$refs.mail_editor.getDesign();      

      this.designFull = [...headerDesign, ...template, ...footerDesign];

      const html = await this.$refs.html_generator.loadDesign();

      const variables = [
        ...this.optionalVariables,
        ...this.requiredVariables,
      ].toString();

      this.model = {       
        application_id: this.selectedApplication,
        unity_id: this.$store.state.auth.currentUser.unity_id,
        notification_type_id: this.selectedNotificationType,
        description: "conteudo",
        type: "content",
        variables,
        design: JSON.stringify(template),
        html,
        header_id: this.headerSelected,
        footer_id: this.footerSelected,
        background_id: this.backgroundSelected,
        legacy: "0",
        active: "1",
      };

      this.check_invalid_tags();
    },
    async check_invalid_tags() {
      this.invalidTags = [];
      this.requiredTags = [];
      const html = await this.$refs.mail_editor.getHtml();
      const { invalid, required } = this.check_tag(
        html,
        this.optionalVariables,
        this.requiredVariables
      );

      if (invalid.length > 0 || required.length > 0) {
        this.invalidTags = invalid;
        this.requiredTags = required;

        console.log(this.invalidTags)
        console.log(this.requiredTags)
        this.$refs.tagError.open();
        return false;
      }
      this.check_if_exists();
    },
    check_if_exists() {
      const existing_template = _.filter(this.templates, (t) => {
        return (
          t.type == "content" &&
          t.application_id == this.selectedApplication &&
          t.notification_type_id == this.selectedNotificationType &&
          t.active == "1"
        );
      });
      if (
        existing_template.length > 0 &&
        existing_template[0].id !== this.template_id
      ) {
        this.existingTemplate = existing_template[0];
        this.$refs.preview_modal.open();
      } else {
        this.submit();
      }
    },
    async submit() {
      
      if (this.existingTemplate.id && this.model.active == "1") {
        this.existingTemplate.active = "0";

        await this.$store.dispatch(
          "templates/update_template",
          this.existingTemplate
        );
      }
      
      await this.$store.dispatch("templates/save_template", this.model);
      this.$refs.preview_modal.close();
      this.$refs.submit_success.open()
    },        
    close_preview() {
      this.$refs.preview_modal.close();
      this.existingTemplate = {}
    },
  },
  mounted() {
    this.$store.dispatch("templates/new_template_init");
  },
};
</script>

<style>
</style>